.json-preview {
    width: 50px;
    text-align: center;
}

.json-preview:hover {
    background: #EDF1F7;
    cursor: pointer;
}

.json-copy{
    cursor: pointer;
    position: absolute;
    right: 2.5rem;
}